import { config } from "../functions/config";

export default function Strings() {
  return {
    tokenAddress: "TOKEN ADDRESS",
    logoTitle: "Logo",
    aboutTtl: "Sobre",
    wpTtl: "Whitepaper",
    allocTtl: "Atribuição de fichas",
    deckTtl: "Convés de Pitch",
    presaleTtl: "Pré-venda",
    rmTtl: "Roteiro",
    auditTtl: "Auditoria",
    projectTtl: "Roteiro",
    quickLinksTtl: "Quick links",
    privPolicy: "Política de Privacidade",
    termsAndConds: "Termos e Condições",
    amlPolicy: "Política Anti-Lavagem de Dinheiro",
    sitemap: "Mapa do site",
    salePolicy: `${config.tokenSymbol} Termos e Condições de Venda`,
    descText1:
      "Salve o reino da anarquia neste RPG de acção imersiva e móvel! Lute contra inimigos do antigo submundo usando feitiços mágicos e armas de ferro ferozes! Siga os nossos sociais para se manter actualizado!",
    descText2:
      "Copyright © 2022-2024 Hitbox Holdings SVG LLC, a St Vincent Corporation",
    descText3:
      "Suite 305 Griffith Corporate Centre, Kingstown, St Vincent, and the Grenadines.",
    quickLinks: "Quick links",
    // presaleStage: 'Presale stage',
    presaleStage: "ÚLTIMA RODADA DE PRÉ-VENDA!",
    presaleEnded: "PRÉ-VENDA ENCERRADA!",
    stayTuned:
      "Por favor, fique atento às instruções de reivindicação de tokens.",
    thankyouForParticipating: "OBRIGADO POR PARTICIPAR NA PRÉ-VENDA DA SWDTKN!",
    // buyBeforeTheEnd: "Hurry before the end of presale!",
    buyBeforeTheEnd: "Please wait for announcements",
    buyBefore: "Fique atento aos anúncios do IDO. Preço de lançamento:",
    soldTtl: "VENDIDO",
    raisedTtl: "RAIZADO",
    connectWallet: "Connect Wallet",
    minimumPurchWarn: `Purchase amount must be not less than {VALUE1} ${config.tokenSymbol} and no more than {VALUE2} ${config.tokenSymbol}.`,
    minimumPurchWarn2: `Purchase amount must be no more than {VALUE1} ${config.tokenSymbol}.`,
    presaleInactive: "Presale is inactive.",
    insufFunds: "You have not enough money to make a transaction.",
    impossToTx: "It is impossible to make a transaction.",
    invalidTime: "Invalid time.",
    stayAndWait:
      "Please stay on the website and wait for the transaction confirmation",
    goesWrong: "You canceled the operation or something goes wrong.",
    thankYou: "Thank you for your purchase!",
    tokPayFailed: "Token payment failed",
    claimNoFunds: "You have no tokens available for withdrawal.",
    statClaimed: `Your ${config.tokenSymbol} tokens will be sent to your address soon.`,
    connectNote:
      "*Please note that, this action can not be undone once the wallet is authorize.",
    connectTheWallet: "Connect the wallet",
    youOwn: "You own",
    buyWith: "Buy with",
    claimTokens: "Claim",
    buyTokenWith: `Buy ${config.tokenSymbol} with`,
    minimumTtl: "Minimum",
    buyDesc: `Enter the amount of ${config.tokenSymbol} you wish to purchase, then click "Buy"`,
    buyTtl: "Buy",
    payTtl: "Pay",
    benefit1: "Acesso exclusivo ao beta fechado do MVP",
    benefit2: "Acesso ao diário de desenvolvimento",
    benefit3: "Arma lendária limitada dentro do jogo",
    benefit4: "Lista branca automática para futuras vendas de NFT",
    benefit5: "Entrada para sorteios phygital",
    benefit6: "E mais!",
    whybuyToken1: "O MVP é jogável e a versão beta será lançada em maio",
    whybuyToken2: "4 milhões de downloads combinados no Android e iOS",
    whybuyToken3: "6 milhões em receita para a versão MVP",
    whybuyToken4:
      "Plano abrangente de 2 anos para expansão do desenvolvimento de jogos e aquisição de usuários",
    whybuyToken5:
      "A equipe é composta por veteranos no desenvolvimento de jogos",
    approveMessage: `Payment with stablecoins will require confirmation of two transactions: first to approve spending and second to ${config.tokenSymbol} purchase. Stay on the website until the all transactions are confirmed. Only ERC-20 tokens supported.`,
    wygTitle1: "Mais de 4M downloads na App Store e Playstore",
    wygTitle2: "6M+ USD em receitas de compras noapp",
    wygTitle3: "4,8 de 5 estrelas ",
    wygTitle4: "250+ horas de jogo de campanha",
    wygTitle5: "Múltiplos modos de jogo assíncronos disponíveis",
    wygTitle6: "lançamento do web3 MVP em setembro de 2024",
    wygTitle7: "O projecto é gerido por veteranos do desenvolvimento de jogos",
    wygTitle8: "Cross-play activado para telemóvel e PC",
    wygTitle9: "Mapa PvP multiplayer altamente imersivo para 16 jogadores",
    wygTitle10: "Experimente jogos em 4k a 60fps (PC)",
    modeT1: "Caçada aos Monstros",
    modeT2: "Fortaleza do Chefe",
    modeT3: "JxJ",
    modeT4: "Masmorras Diárias",
    modeT5: "Masmorra de Evento",
    modeT6: "Campanha",
    modeInfo:
      "O MVP Swords of Blood apresenta vários modos de jogo! Obtenha acesso exclusivo ao beta fechado (lançamento em maio) ao participar do atual",
    aboutDesc1: `Com combate chamativo, gráficos estelares e vários modos de jogo, ${config.appName} é o primeiro RPG hack-and-slash F2P de qualidade AAA no lançamento do blockchain na Telos`,
    aboutDesc2:
      "A violência, a traição e o mal astuto provocaram a brutal queda do mundo de Ezura. O Traidor, um ser de imenso mal, aniquilou os protectores vigorosos do reino e durante 1.000 anos reinou a escuridão.",
    aboutDesc3:
      "É uma época de desespero, desespero e morte, pois os exércitos do Traidor cobrem a terra.",
    aboutDesc4:
      "Mas das ruínas, surge uma possível salvação; a última da outrora poderosa ordem guerreira foi ressuscitada por uma antiga deusa conhecida como a Sentinela. Juntos, tentarão trazer uma centelha de esperança para a terra desolada.",
    aboutDesc5:
      "Torne-se um destemido rastejador de masmorras, um caçador de recompensas, ou um poderoso guerreiro, moendo para pilhar e equipamento raro. Cria equipamento épico, dobra a magia da natureza à tua vontade, e vinga os teus irmãos caídos numa busca épica de poder e vingança.",
    contactUsDesc: `Torne-se um embaixador das ${config.appName} e ganhe fichas de jogo, ouro e pedras preciosas.`,
    contactUs: "Contact Us",
    asSeenOnTtl: "Como visto em",
    facTtl1: "Descubra o belo mundo de Ezura",
    facTtl2: "Dominar uma grande variedade de armas e elementos",
    facTtl3: "Construir combinações de ataque devastadoras",
    facTtl4: "Artes e armas únicas e poderosas",
    facTtl5: "Use a jogabilidade de hack n slash para matar os seus oponentes",
    facTtl6: "Compete com outros jogadores num PvP de ritmo rápido e brutal",
    teamName1: "James Seaman",
    teamTtl1: "CEO",
    teamBio1:
      "A 36-year veteran of the video game industry who has worked on game titles, such as The Sims, Sims City, Ghost Recon, Rainbow Six, and more), on many levels - from programming to CEO.",
    teamName2: "Jeremy Brown",
    teamTtl2: "COO",
    teamBio2:
      "Worked extensively on the Sony PlayStation and Xbox marketing accounts as well as some of the most iconic game titles including Halo, Call of Duty, Assassin’s Creed and the Tom Clancy franchises.",
    teamName3: "Mariusz Szynalik",
    teamTtl3: "Project Director for HitBox Games",
    teamBio3:
      "Over 10 years of experience as a gaming executive focused on video games publishing and sales of PC/consoles and mobile Free-To-Play and blockchain-enabled games. Cofounder of Artifex Mundi S.A.",
    teamName4: "Yupeng Qin",
    teamTtl4: "Project Director for Asian Dev Team",
    teamBio4:
      "Over ten years of experience in community building, marketing, public and client relations, content writing andcreation, and social media management including projects in web3, gamefi, and defi.",
    teamName5: "Jakub Szamalek",
    teamTtl5: "Narrative Director",
    teamBio5: "A decade of experience in UI/UX design",
    teamName6: "John Moyer",
    teamTtl6: "Bass Guitarist of the band Disturbed",
    teamBio6:
      "Former Director of Narrative Design for Cyberpunk and The Witcher 3",
    teamName7: "Vee Lozano",
    teamTtl7: "Business Development Lead",
    teamBio7:
      "Star Judge and world champion bladesmith on the History Channel’s hit show, Forged in Fire",
    teamName8: "Jason Hung",
    teamTtl8: "Advisor",
    teamBio8:
      "Lead writer for the upcoming megahit: Gord Author and Hollywood Screenplay Writer",
    teamName9: "Charlie Hu",
    teamTtl9: "Advisor",
    teamName10: "Adam Vine",
    teamTtl10: "Narrative Design Director",
    teamName11: "Ben Abbott",
    teamTtl11: "Weapons Creator and Consultant ",
    teamName12: "Kamran Sadikhov",
    teamTtl12: "UI/UX Designer",
    teamTtl: "Team",
    partnersTtl: "Partners",
    backersTtl: "Backers",
    launchPartnTtl: "Parceiros de Lançamento",
    techPartnTtl: "Parceiros tecnológicos",
    comPartnTtl: "Parceiros comunitários",
    presTtl: "Como comprar",
    presStep1: "PASSO 1",
    presStep2: "PASSO 2",
    presStep3: "PASSO 3",
    presStep1Desc:
      "Primeiro, certifique-se de que tem a carteira MetaMask instalada no seu browser, ou use uma das carteiras para se ligar a uma das carteiras suportadas (recomendamos Carteira de Confiança).<br/>Comprando a partir de um browser de secretária, terá uma experiência de compra mais suave. Para tal, recomendamos a Metamask.<br/>Se estiver a comprar num telemóvel, recomendamos a utilização da Carteira de Confiança e a ligação através do browser incorporado (basta copiar https://swordsofblood.com para o browser da Carteira de Confiança).",
    presStep2Desc:
      "Quando tiver o fornecedor da carteira que pretende utilizar, clique em Ligar carteira e seleccione a opção apropriada. No caso de uma aplicação de carteira móvel, terá de seleccionar Wallet Connect.",
    presStep3Desc: `Pode adquirir ${config.tokenSymbol} com ERC20 ETH, USDT, USDC, e DAI. Depois de todas as fases de pré-venda terem terminado, poderá reclamar o seu ${config.tokenSymbol} através desta página. Por favor, aguarde pelo nosso anúncio. `,

    date: {
      Days: "DIAS",
      Hours: "HORAS",
      Minutes: "ACTAS",
      Seconds: "SEGUNDOS",
    },
    timerLabel: "A venda antecipada 3 termina em :",
    investorTtl: "Investidores da Ronda das Sementes",
  };
}

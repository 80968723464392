import { config } from "../functions/config";
export default function Strings() {
  return {
    tokenAddress: "TOKEN ADDRESS",
    logoTitle: "Logo",
    aboutTtl: "Acerca de",
    wpTtl: "Whitepaper",
    allocTtl: "Asignación de tokens",
    deckTtl: "Pitch Deck",
    presaleTtl: "Preventa",
    rmTtl: "Hoja de ruta",
    auditTtl: "Auditoría",
    projectTtl: "Proyecto",
    quickLinksTtl: "Quick links",
    privPolicy: "Política de privacidad",
    termsAndConds: "Política de privacidad",
    amlPolicy: "Política contra el blanqueo de capitales",
    sitemap: "mapa del sitio",
    salePolicy: `Condiciones de venta de ${config.tokenSymbol}`,
    descText1:
      "Salva al reino de la anarquía en este RPG de acción móvil y envolvente. Lucha contra enemigos del antiguo inframundo usando hechizos mágicos y feroces armas de hierro. ¡Sigue nuestras redes sociales para estar al día!",
    descText2:
      "Copyright © 2022-2024 Hitbox Holdings SVG LLC, a St Vincent Corporation",
    descText3:
      "Suite 305 Griffith Corporate Centre, Kingstown, St Vincent, and the Grenadines.",
    quickLinks: "Quick links",
    // presaleStage: 'Presale stage',
    presaleStage: "¡ÚLTIMA RONDA DE PREVENTA!",
    presaleEnded: "¡PREVENTA FINALIZADA!",
    stayTuned:
      "Por favor, manténgase atento a las instrucciones para reclamar los tokens.",
    thankyouForParticipating:
      "¡GRACIAS POR PARTICIPAR EN LA PREVENTA DE SWDTKN!",
    // buyBeforeTheEnd: "Hurry before the end of presale!",
    buyBeforeTheEnd: "Please wait for announcements",
    buyBefore: "Estén atentos a los anuncios de IDO. Precio de lanzamiento:",
    soldTtl: "Vendido",
    raisedTtl: "RECAUDADO",
    connectWallet: "Connect Wallet",
    minimumPurchWarn: `Purchase amount must be not less than {VALUE1} ${config.tokenSymbol} and no more than {VALUE2} ${config.tokenSymbol}.`,
    minimumPurchWarn2: `Purchase amount must be no more than {VALUE1} ${config.tokenSymbol}.`,
    presaleInactive: "Presale is inactive.",
    insufFunds: "You have not enough money to make a transaction.",
    impossToTx: "It is impossible to make a transaction.",
    invalidTime: "Invalid time.",
    stayAndWait:
      "Please stay on the website and wait for the transaction confirmation",
    goesWrong: "You canceled the operation or something goes wrong.",
    thankYou: "Thank you for your purchase!",
    tokPayFailed: "Token payment failed",
    claimNoFunds: "You have no tokens available for withdrawal.",
    statClaimed: `Your ${config.tokenSymbol} tokens will be sent to your address soon.`,
    connectNote:
      "*Please note that, this action can not be undone once the wallet is authorize.",
    connectTheWallet: "Connect the wallet",
    youOwn: "You own",
    buyWith: "Buy with",
    claimTokens: "Claim",
    buyTokenWith: `Buy ${config.tokenSymbol} with`,
    minimumTtl: "Minimum",
    buyDesc: `Enter the amount of ${config.tokenSymbol} you wish to purchase, then click "Buy"`,
    buyTtl: "Buy",
    payTtl: "Pay",
    benefit1: "Acceso exclusivo al beta cerrado del MVP",
    benefit2: "Acceso al diario de desarrollo",
    benefit3: "Arma legendaria limitada \n en el juego",
    benefit4:
      "Inclusión automática en la lista blanca para ventas futuras de NFT",
    benefit5: "Entrada a sorteos físicos y digitales",
    benefit6: "¡Y mucho más!",
    whybuyToken1: "El MVP es jugable y la beta estará disponible en mayo",
    whybuyToken2: "4 millones de descargas combinadas en Android e iOS",
    whybuyToken3: "6 millones en ingresos para la versión MVP",
    whybuyToken4:
      "Plan integral de 2 años para la expansión del desarrollo de juegos y la adquisición de usuarios",
    whybuyToken5:
      "El equipo está compuesto por veteranos en el desarrollo de juegos",
    approveMessage: `Payment with stablecoins will require confirmation of two transactions: first to approve spending and second to ${config.tokenSymbol} purchase. Stay on the website until the all transactions are confirmed. Only ERC-20 tokens supported.`,
    wygTitle1: "Un dinámico juego de rol",
    wygTitle2: "Jugabilidad altamente inmersiva y bellamente renderizada",
    wygTitle3: "Modos PvE y PvP",
    wygTitle4: "Más de 250 horas de juego en campaña",
    wygTitle5: "Disponible en múltiples modos asincrónicos",
    wygTitle6: "El MVP de Web3 se lanzará en septiembre de 2024.",
    wygTitle7:
      "El proyecto está dirigido por veteranos en el desarrollo de juegos",
    wygTitle8: "Posibilidad de juego cruzado entre móvil y PC",
    wygTitle9:
      "Mapa de jugador contra jugador altamente inmersivo para 16 jugadores",
    wygTitle10: "Experiencia de juego en 4K a 60fps (PC)",
    modeT1: "Caza de Monstruos",
    modeT2: "Fortaleza del Jefe",
    modeT3: "PvP",
    modeT4: "Mazmorras Diarias",
    modeT5: "Mazmorra de Evento",
    modeT6: "Campaña",
    modeInfo:
      "¡El MVP de Swords of Blood cuenta con varios modos de juego! Obtén acceso exclusivo a la beta cerrada (lanzamiento en mayo) al participar en la",
    aboutDesc1: `Con un combate llamativo, gráficos estelares y varios modos de juego, ${config.appName} es el primer RPG F2P hack-and-slash de ritmo rápido y calidad AAA de la cadena de bloques que se lanza en Telos.`,
    aboutDesc2:
      "La violencia, la traición y la astucia del mal han provocado la brutal caída del mundo de Ezura. El Traidor, un ser de inmensa maldad, ha aniquilado a los incondicionales protectores del reino y durante 1.000 años ha reinado la oscuridad.",
    aboutDesc3:
      "Es una época de desesperación, desesperación y muerte, ya que los ejércitos del Traidor cubren la tierra.",
    aboutDesc4:
      "Pero de entre las ruinas surge una posible salvación: el último de los antaño poderosos guerreros ha sido resucitado por una antigua diosa conocida como la Centinela. Juntos, intentaréis traer una chispa de esperanza a la desolada tierra.",
    aboutDesc5:
      "Conviértete en un intrépido rastreador de mazmorras, en un mago de batalla cazador de recompensas o en un poderoso guerrero. Crea equipo épico, somete la magia de la naturaleza a tu voluntad y véngate de tus hermanos caídos en una búsqueda épica de poder y venganza.",
    contactUsDesc: `Conviértete en embajador de ${config.appName} y gana fichas de juego, oro y gemas.`,
    contactUs: "Contact Us",
    asSeenOnTtl: "Visto en",
    facTtl1: "Descubre el hermoso mundo de Ezura",
    facTtl2: "Domina una gran variedad de armas y elementos",
    facTtl3: "Construye combinaciones de ataque devastadoras",
    facTtl4: "Crea poderosos engranajes y armas únicas",
    facTtl5:
      "Utiliza el modo de juego hack n slash para acabar con tus oponentes.",
    facTtl6: "Compite con otros jugadores en un trepidante y brutal PvP.",
    teamBio1:
      "A 36-year veteran of the video game industry who has worked on game titles, such as The Sims, Sims City, Ghost Recon, Rainbow Six, and more), on many levels - from programming to CEO.",
    teamBio2:
      "Worked extensively on the Sony PlayStation and Xbox marketing accounts as well as some of the most iconic game titles including Halo, Call of Duty, Assassin’s Creed and the Tom Clancy franchises.",
    teamBio3:
      "Over 10 years of experience as a gaming executive focused on video games publishing and sales of PC/consoles and mobile Free-To-Play and blockchain-enabled games. Cofounder of Artifex Mundi S.A.",
    teamBio4:
      "Over ten years of experience in community building, marketing, public and client relations, content writing andcreation, and social media management including projects in web3, gamefi, and defi.",
    teamBio5: "A decade of experience in UI/UX design",
    teamBio6:
      "Former Director of Narrative Design for Cyberpunk and The Witcher 3",
    teamBio7:
      "Star Judge and world champion bladesmith on the History Channel’s hit show, Forged in Fire",
    teamBio8:
      "Lead writer for the upcoming megahit: Gord Author and Hollywood Screenplay Writer",
    teamName1: "James Seaman",
    teamTtl1: "CEO",
    teamName2: "Jeremy Brown",
    teamTtl2: "COO",
    teamName3: "Mariusz Szynalik",
    teamTtl3: "Project Director for HitBox Games",
    teamName4: "Yupeng Qin",
    teamTtl4: "Project Director for Asian Dev Team",
    teamName5: "Jakub Szamalek",
    teamTtl5: "Narrative Director",
    teamName6: "John Moyer",
    teamTtl6: "Bass Guitarist of the band Disturbed",
    teamName7: "Vee Lozano",
    teamTtl7: "Business Development Lead",
    teamName8: "Jason Hung",
    teamTtl8: "Advisor",
    teamName9: "Charlie Hu",
    teamTtl9: "Advisor",
    teamName10: "Adam Vine",
    teamTtl10: "Narrative Design Director",
    teamName11: "Ben Abbott",
    teamTtl11: "Weapons Creator and Consultant ",
    teamName12: "Kamran Sadikhov",
    teamTtl12: "UI/UX Designer",
    teamTtl: "Team",
    partnersTtl: "Partners",
    backersTtl: "Patrocinadores",
    launchPartnTtl: "Socios de lanzamiento",
    techPartnTtl: "Socios tecnológicos",
    comPartnTtl: "Socios comunitarios",
    presTtl: "Cómo comprar",
    presStep1: "PASO 1",
    presStep2: "PASO 2",
    presStep3: "PASO 3",
    presStep1Desc:
      "En primer lugar, asegúrate de que tienes el monedero MetaMask instalado en tu navegador, o utiliza uno de los monederos para conectarte a uno de los monederos soportados (recomendamos Trust Wallet).<br/>Comprar desde un navegador de escritorio te dará una experiencia de compra más fluida. Para ello recomendamos Metamask.<br/>Si compra desde un teléfono móvil, le recomendamos que utilice Trust Wallet y se conecte a través del navegador integrado (sólo tiene que copiar https://swordsofblood.com al navegador de Trust Wallet).",
    presStep2Desc:
      "Una vez que tenga el proveedor de monedero que desea utilizar, haga clic en Conectar monedero y seleccione la opción adecuada. En el caso de una aplicación de monedero móvil, tendrás que seleccionar Conectar monedero.",
    presStep3Desc: `Puedes comprar ${config.tokenSymbol} con ERC20 ETH, USDT, USDC y DAI. Una vez finalizadas todas las fases de preventa, podrá reclamar su ${config.tokenSymbol} a través de esta página. Por favor, espere a nuestro anuncio.`,

    date: {
      Days: "DÍAS",
      Hours: "HORAS",
      Minutes: "MINUTOS",
      Seconds: "SEGUNDOS",
    },
    timerLabel: "La preventa 3 finaliza en",
    investorTtl: "Inversores de la ronda de siembra",
  };
}
